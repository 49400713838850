import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faArrowUp, faArrowDown, faSync } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'jspdf-autotable';
import baseURL from '../../url';
import { Link as Anchor, } from "react-router-dom";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logo from '../../../images/logo.png'
export default function AsistenciasData() {
    const [asistencias, setAsistencias] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [empleado, setEmpleado] = useState({});
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [dni, setDni] = useState('');
    const [cargo, setCargo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [createdAt, setcreatedAt] = useState('');
    const [idEmpleado, setidEmpleado] = useState('');
    const [idAsistencia, setidAsistencia] = useState('');
    const [tipo, setTipo] = useState('');
    const [filtroId1, setFiltroId1] = useState('');
    const [filtroId, setFiltroId] = useState('');
    const [filtroDni, setFiltroDni] = useState('');
    const [filtroTipo, setFiltroTipo] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [contactos, setContactos] = useState([]);

    useEffect(() => {
        cargarAsistencias();
        cargarContacto();
    }, []);

    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto.reverse().slice(0, 1) || []);
                console.log(data.contacto)
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };
    const cargarAsistencias = () => {
        fetch(`${baseURL}/asistenciaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setAsistencias(data.asistencias || []);
            })
            .catch(error => console.error('Error al cargar asistencias:', error));
    };

    const eliminar = (idAsistencia) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/asistenciaDelete.php?idAsistencia=${idAsistencia}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarAsistencias();
                    })
                    .catch(error => {
                        console.error('Error al eliminar :', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (empleado) => {
        setEmpleado(empleado);
        setNombre(empleado.nombre);
        setApellido(empleado.apellido);
        setDni(empleado.dni);
        setCargo(empleado.cargo);
        setTelefono(empleado.telefono);
        setEmail(empleado.email);
        setcreatedAt(empleado.createdAt);
        setidEmpleado(empleado.idEmpleado);
        setidAsistencia(empleado.idAsistencia);
        setTipo(empleado.tipo);
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };
    // Función para filtrar por fechas
    const filtrarPorFechas = (item) => {
        const fechaItem = new Date(item.createdAt);
        if (fechaInicio && fechaFin) {
            const fechaInicioDate = new Date(fechaInicio);
            const fechaFinDate = new Date(fechaFin);
            return fechaItem >= fechaInicioDate && fechaItem <= fechaFinDate;
        } else if (fechaInicio) {
            const fechaInicioDate = new Date(fechaInicio);
            return fechaItem >= fechaInicioDate;
        } else if (fechaFin) {
            const fechaFinDate = new Date(fechaFin);
            return fechaItem <= fechaFinDate;
        }
        return true; // Si no se establecen fechas, mostrar todos
    };

    const Filtrados = asistencias.filter(item => {

        const idUsuarioMatch1 = item.idAsistencia.toString().includes(filtroId1);
        const idUsuarioMatch = item.idEmpleado.toString().includes(filtroId);
        const dniMatch = item.dni.toString().includes(filtroDni.toLowerCase());
        const tipoMatch = !filtroTipo || item.tipo.includes(filtroTipo);
        const fechaMatch = filtrarPorFechas(item); // Nuevo filtro de fechas
        return idUsuarioMatch && idUsuarioMatch1 && dniMatch && tipoMatch && fechaMatch;
    });
    const recargar = () => {
        cargarAsistencias();
    };
    const invertirOrden = () => {
        setAsistencias([...asistencias].reverse());
        setOrdenInvertido(!ordenInvertido);
    };
    const descargarExcel = () => {
        const data = Filtrados.map(item => ({
            'ID Asistencia': item.idAsistencia,
            'ID Empleado': item.idEmpleado,
            Nombre: item.nombre,
            Apellido: item.apellido,
            DNI: item.dni,
            Cargo: item.cargo,
            // Telefono: item.telefono,
            // Email: item.email,
            Tipo: item.tipo,
            Fecha: item.createdAt,

        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Asistencias');
        XLSX.writeFile(wb, 'asistencias.xlsx');
    };

    const descargarPDF = () => {
        const pdf = new jsPDF();
        const contacto = contactos[0];

        if (contacto) {
            const startX = 15; // Posición X inicial del primer dato de contacto
            const startY = 15; // Posición Y de los datos de contacto
            const spacingY = 5; // Espacio vertical entre cada dato de contacto

            // Agregar el logo a la izquierda de la página con un tamaño más pequeño
            const imgWidth = 10; // Ancho del logo reducido
            const imgHeight = 10; // Alto del logo reducido
            const imgX = startX; // Posición X del logo
            const imgY = startY; // Posición Y del logo
            pdf.addImage(logo, 'PNG', imgX, imgY, imgWidth, imgHeight);

            // Calcular la longitud del texto "REGISTRO DE ASISTENCIA" para alinearlo a la derecha
            const textWidth = pdf.getStringUnitWidth('REGISTRO DE ASISTENCIA') * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            const textX = pdf.internal.pageSize.width - textWidth - 18; // Posición X para alinear a la derecha

            // Texto "REGISTRO DE ASISTENCIA" alineado a la derecha con tamaño de fuente 16 y en negrita
            pdf.setFontSize(16); // Reducimos el tamaño de fuente
            pdf.setFont('helvetica', 'bold');
            pdf.text(`REGISTRO DE ASISTENCIA`, textX, startY + 7); // Ajusta la posición Y para centrar el texto verticalmente
            pdf.setFont('helvetica', 'normal');

            // Texto "INFORMACIÓN DE LA EMPRESA" en negrita con tamaño de fuente 10 debajo de "REGISTRO DE ASISTENCIA"
            pdf.setFontSize(10);
            pdf.setFont('helvetica', 'bold');
            pdf.text(`INFORMACIÓN DE LA EMPRESA`, startX, startY + spacingY + 12); // Ajusta la posición Y para dejar más espacio para el logo
            pdf.setFont('helvetica', 'normal');

            // Datos de contacto debajo de "INFORMACIÓN DE LA EMPRESA" con tamaño de fuente 9
            pdf.setFontSize(9);
            pdf.text(`NOMBRE:   ${contacto.nombre}`, startX, startY + 2 * spacingY + 12);
            pdf.text(`DIRECCIÓN:   ${contacto.direccion}`, startX, startY + 3 * spacingY + 12);
            pdf.text(`EMAIL:   ${contacto.email}`, startX, startY + 4 * spacingY + 12);
            pdf.text(`TELÉFONO:   ${contacto.numero}`, startX, startY + 5 * spacingY + 12);

        }


        const columns = [
            { title: 'ID Asist', dataKey: 'idAsistencia' },
            // { title: 'ID Empleado', dataKey: 'idEmpleado' },
            { title: 'Nombre', dataKey: 'nombre' },
            { title: 'Apellido', dataKey: 'apellido' },
            { title: 'Legajo', dataKey: 'dni' },
            { title: 'Cargo', dataKey: 'cargo' },
            // { title: 'Telefono', dataKey: 'telefono' },
            // { title: 'Email', dataKey: 'email' },
            { title: 'Tipo', dataKey: 'tipo' },
            { title: 'Fecha', dataKey: 'createdAt' },
        ];

        const data = Filtrados.map(item => ({
            idAsistencia: item.idAsistencia,
            // idEmpleado: item.idEmpleado,
            nombre: item.nombre,
            apellido: item.apellido,
            dni: item.dni,
            cargo: item.cargo,
            // telefono: item.telefono,
            // email: item.email,
            tipo: item.tipo,
            createdAt: item.createdAt,
        }));
        const startY = contacto ? 55 : 10;
        pdf.autoTable({
            head: [columns.map(col => col.title)],
            body: data.map(item => Object.values(item)),
            startY: startY,
        });

        pdf.save('asistencias.pdf');
    };
    return (
        <div>
            <ToastContainer />

            <div className='deFlexContent'>
                <div className='deFlex2'>
                    <Anchor to={'/'} className='btnSave'>
                        <span>+</span> Agregar
                    </Anchor>
                    <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                    <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>

                </div>


                <div className='filtrosContain'>


                    <div className='inputsColumn'>

                        <input type="number" value={filtroId1} onChange={(e) => setFiltroId1(e.target.value)} placeholder='ID Asistencia' />
                    </div>




                    <div className='inputsColumn'>

                        <input type="text" value={filtroDni} onChange={(e) => setFiltroDni(e.target.value)} placeholder='DNI' />
                    </div>

                    <div className='inputsColumn'>

                        <select value={filtroTipo} onChange={(e) => setFiltroTipo(e.target.value)}>
                            <option value="">Tipo</option>
                            <option value="entrada">Entrada</option>
                            <option value="salida">Salida</option>
                        </select>
                    </div>
                    <div className='inputsColumn'>
                        <input type="date" value={fechaInicio} onChange={(e) => setFechaInicio(e.target.value)} />
                    </div>
                    <div className='inputsColumn'>
                        <input type="date" value={fechaFin} onChange={(e) => setFechaFin(e.target.value)} />
                    </div>
                    <button className='reload' >
                        {Filtrados?.length}
                    </button>

                    <button className='reload' onClick={recargar}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>


                </div>
            </div>


            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>ID Asistencia</th>
                            <th>ID Bombero</th>
                            <th>Nombre</th>
                            <th>Apellido</th>
                            <th>Legajo</th>
                            <th>Tipo</th>
                            <th>Fecha</th>
                            <th>Acciones</th>

                        </tr>
                    </thead>
                    <tbody>
                        {Filtrados.map(empleado => (
                            <tr key={empleado.idAsistencia}>
                                <td>{empleado.idAsistencia}</td>
                                <td>{empleado.idEmpleado}</td>
                                <td>{empleado.nombre}</td>
                                <td>{empleado.apellido}</td>
                                <td>{empleado.dni}</td>
                                <td style={{
                                    color: empleado?.tipo === 'salida' ? '#DAA520' : empleado?.tipo === 'entrada' ? '#008000' : '#FF0000',

                                }}>  {`${empleado?.tipo}`}</td>
                                <td>{empleado.createdAt}</td>
                                <td>
                                    <button className='eliminar' onClick={() => eliminar(empleado.idAsistencia)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal(empleado)}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>
                                Visualizacion
                            </button>
                            <button onClick={cerrarModal} className='close'>
                                &times;
                            </button>
                        </div>
                        <div className='sectiontext'>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>ID Asisetncia</legend>
                                    <input
                                        type="idAsistencia"
                                        disabled
                                        value={idAsistencia}
                                        onChange={(e) => setidAsistencia(e.target.idAsistencia)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>ID Bombero</legend>
                                    <input
                                        type="idEmpleado"
                                        disabled
                                        value={idEmpleado}
                                        onChange={(e) => setidEmpleado(e.target.idEmpleado)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Tipo</legend>
                                    <input
                                        type="tipo"
                                        disabled
                                        value={tipo}
                                        onChange={(e) => setTipo(e.target.tipo)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Fecha</legend>
                                    <input
                                        type="createdAt"
                                        disabled
                                        value={createdAt}
                                        onChange={(e) => setcreatedAt(e.target.createdAt)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Nombre</legend>
                                    <input
                                        type="text"
                                        disabled
                                        value={nombre !== '' ? nombre : empleado.nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Apellido</legend>
                                    <input
                                        type="text"
                                        disabled
                                        value={apellido !== '' ? apellido : empleado.apellido}
                                        onChange={(e) => setApellido(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Legajo</legend>
                                    <input
                                        type="text"
                                        disabled
                                        value={dni !== '' ? dni : empleado.dni}
                                        onChange={(e) => setDni(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Cargo</legend>
                                    <input
                                        type="text"
                                        disabled
                                        value={cargo !== '' ? cargo : empleado.cargo}
                                        onChange={(e) => setCargo(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Telefono</legend>
                                    <input
                                        type="text"
                                        disabled
                                        value={telefono !== '' ? telefono : empleado.telefono}
                                        onChange={(e) => setTelefono(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Email</legend>
                                    <input
                                        type="email"
                                        disabled
                                        value={email !== '' ? email : empleado.email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </fieldset>

                            </div>

                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
