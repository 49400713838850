import React, { useState } from 'react';
import './NewEmpleado.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';

export default function NewEmpleado() {
    const [mensaje, setMensaje] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [dni, setDni] = useState('');
    const [cargo, setCargo] = useState('');
    const [telefono, setTelefono] = useState('');
    const [email, setEmail] = useState('');
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setNombre('');
        setApellido('');
        setDni('');
        setCargo('');
        setTelefono('');
        setEmail('');
        setMensaje('');
        setModalOpen(!modalOpen);
    };


    const crear = async () => {
        const formData = new FormData();
        formData.append('nombre', nombre);
        formData.append('apellido', apellido);
        formData.append('dni', dni);
        formData.append('cargo', cargo);
        formData.append('telefono', telefono);
        formData.append('email', email);

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/empleadosPost.php`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                toast.success(data.mensaje);
                toggleModal();
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className='NewContain'>
            <ToastContainer />
            <button onClick={toggleModal} className='btnSave'>
                <span>+</span> Agregar
            </button>
            {modalOpen && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='deFlexBtnsModal'>

                            <button className='selected'>
                                Agregar Bombero
                            </button>
                            <span className='close' onClick={toggleModal}>
                                &times;
                            </span>
                        </div>
                        <form id="crearForm">
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Nombre</legend>
                                    <input
                                        type='text'
                                        name='nombre'
                                        placeholder='Nombre'
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Apellido</legend>
                                    <input
                                        type='text'
                                        name='apellido'
                                        placeholder='Apellido'
                                        value={apellido}
                                        onChange={(e) => setApellido(e.target.value)}
                                    />
                                </fieldset>

                                <fieldset>
                                    <legend>Legajo</legend>
                                    <input
                                        type='number'
                                        name='dni'
                                        placeholder='DNI'
                                        value={dni}
                                        onChange={(e) => setDni(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Cargo</legend>
                                    <input
                                        type='text'
                                        name='cargo'
                                        placeholder='Cargo'
                                        value={cargo}
                                        onChange={(e) => setCargo(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Teléfono</legend>
                                    <input
                                        type='text'
                                        name='telefono'
                                        placeholder='Teléfono'
                                        value={telefono}
                                        onChange={(e) => setTelefono(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Email</legend>
                                    <input
                                        type='email'
                                        name='email'
                                        placeholder='Email'
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </fieldset>
                            </div>


                            {mensaje ? (
                                <button type='button' className='btnLoading' disabled>
                                    {mensaje}
                                </button>
                            ) : (
                                <button type='button' onClick={crear} className='btnPost'>
                                    Agregar
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}
