import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'; // Importar SweetAlert2
import moment from 'moment-timezone';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../url';
import './NewAsistencia.css'
import logo from '../../images/logo.png'
import { Link as Anchor } from "react-router-dom";
export default function NewAsistencia() {
    const [dniAsistencia, setDniAsistencia] = useState('');
    const [fechaHora, setFechaHora] = useState('');
    const [tipoAsistencia, setTipoAsistencia] = useState('entrada');
    const [mensaje, setMensaje] = useState('');
    useEffect(() => {
        const interval = setInterval(() => {
            const currentDateTime = moment().tz('America/Argentina').format('YYYY-MM-DD HH:mm:ss');
            setFechaHora(currentDateTime);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const crearAsistencia = async () => {
        const formData = new FormData();
        formData.append('dni', dniAsistencia);
        formData.append('createdAt', fechaHora);
        formData.append('tipo', tipoAsistencia);
        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/asistenciaPost.php`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.mensaje) {
                // Mostrar SweetAlert2 si la asistencia se crea correctamente
                Swal.fire({
                    icon: 'success',
                    title: `Asistencia de ${tipoAsistencia} registrada`,
                    text: data.mensaje,
                });
                setMensaje('');
                setDniAsistencia('')
            } else if (data.error) {
                // Mostrar SweetAlert2 si hay un error
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: data.error,
                });

                setMensaje('');
            }
        } catch (error) {
            console.error('Error:', error);
            // Mostrar SweetAlert2 si hay un error de conexión
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Error de conexión. Por favor, inténtelo de nuevo.',
            });
            setMensaje('');
        }
    };

    return (
        <div className='formAsistencia'>

            <Anchor to={'/dashboard'}>
                <img src={logo} alt="logo" />

            </Anchor>

            <h3>Registro de Asistencia al Bombero</h3>
            <h2>{fechaHora}</h2>
            <fieldset>
                <legend>Legajo</legend>
                <input
                    type="text"
                    placeholder="Numero de Legajo"
                    value={dniAsistencia}
                    onChange={(e) => setDniAsistencia(e.target.value)}
                />
            </fieldset>
            <fieldset>
                <legend>Tipo</legend>
                <select value={tipoAsistencia} onChange={(e) => setTipoAsistencia(e.target.value)}>
                    <option value="entrada">Entrada</option>
                    <option value="salida">Salida</option>
                </select>
            </fieldset>

            {mensaje ? (
                <button type='button' className='btnLoading2' disabled>
                    {mensaje}
                </button>
            ) : (
                <button onClick={crearAsistencia} className='btn'>Enviar </button>
            )}
        </div>
    );
}
